import React from 'react';
import PropTypes from 'prop-types';
import AddedProfileItem from 'src/components/views/profiles/search/AddedProfileItem';
import NewlyAddedAccountView from 'src/components/account/NewlyAddedAccountView';

const NewlyAddedProfilesView = (props) => {
    const { addedProfileIds } = props;
    return (
        <NewlyAddedAccountView
          description={`The following profile${addedProfileIds.length > 1 ? 's have' : ' has'} been successfully added. We will now begin gathering historical data for your profile${addedProfileIds.length > 1 ? 's' : ''}.`}
          info={(
              <>
                  <b>Interaction data history:</b> Social networks provide some data history for posts and interactions, this varies by network. You will be able to access this data immediately. Learn more on our <a href="https://support.quintly.com/en/articles/4412262-data-availability" target="_blank" rel="noopener noreferrer">knowledge base</a>.
                  <br /><br />
                  <b>Audience data history:</b> Social networks do not provide data history for follower and subscriber counts. Profiles already tracked by quintly will have some data available.
              </>
          )}
        >
            {
                addedProfileIds.map((profileId) => (
                    <AddedProfileItem key={profileId} profileId={profileId} />
                ))
            }
        </NewlyAddedAccountView>
    );
};

NewlyAddedProfilesView.propTypes = {
    addedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default NewlyAddedProfilesView;
