import Button from 'src/components/buttons/Button';
import AdCampaignFilterSummary from 'src/components/filter/AdCampaignFilterSummary';
import PostTagFilterSummary from 'src/components/filter/PostTagFilterSummary';
import CombinedPostTextFilterSummary from 'src/components/filter/CombinedPostTextFilterSummary';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeSelectActivePostTagsByIds } from 'src/selectors/postTags';
import styles from 'src/stylesheets/additionalFilterSelector.scss';
import {
    getAdditionalFilterCount,
    isPostTagSelected,
    isPostTextSelected,
    isPostTextExcludeSelected, isAdCampaignSelected
} from 'src/utils/filterSelectors';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import withTooltip from 'src/components/withToolTip';
import filterStyles from 'src/stylesheets/filter.scss';

const emptyArray = [];
const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();

const ButtonWithToolTip = withTooltip(Button);

const AdditionalFilterButton = ({
    onClick, postText, postTag, postTextExclude, disabled, small, active, showPrefixWhenFilterIsSet, adCampaign
}) => {
    const additionalFilterCount = getAdditionalFilterCount({
        postText, postTag, postTextExclude, adCampaign
    });
    const hasAdditionalFilters = additionalFilterCount > 0;
    const postTagIds = _get(postTag, 'values', emptyArray);
    const postTags = useSelector((state) => selectActivePostTagsByIds(state, postTagIds));
    const error = (postTagIds.length > 0 && (postTags.length !== postTagIds.length));

    if (hasAdditionalFilters) {
        const postTextIncludeAndExcludeFilter = {};
        if (isPostTextSelected(postText)) {
            postTextIncludeAndExcludeFilter.postText = postText;
        }
        if (isPostTextExcludeSelected(postTextExclude)) {
            postTextIncludeAndExcludeFilter.postTextExclude = postTextExclude;
        }

        const tooltip = (
            <div className={filterStyles.button}>
                { isPostTagSelected(postTag) && (
                    <div>
                        <PostTagFilterSummary input={postTag} state="fullyApplied" />
                    </div>
                )}
                { (isPostTextSelected(postText) || isPostTextExcludeSelected(postTextExclude)) && (
                    <div>
                        <CombinedPostTextFilterSummary
                          state="fullyApplied"
                          input={postTextIncludeAndExcludeFilter}
                        />
                    </div>
                )}
                {
                    isAdCampaignSelected(adCampaign) && (
                        <div>
                            <AdCampaignFilterSummary input={adCampaign} state="fullyApplied" />
                        </div>
                    )
                }
            </div>
        );

        const wording = additionalFilterCount === 1 ? 'Filter' : 'Filters';
        return (
            <div className={styles.additionalFilterButtonWrapper}>
                {showPrefixWhenFilterIsSet
                && (
                <div className={classnames(styles.and, {
                    [styles.disabled]: disabled,
                    [styles.small]: small
                })}
                >+
                </div>
                )}
                <ButtonWithToolTip
                  warning={error}
                  icon="filters"
                  active={active}
                  disabled={disabled}
                  small={small}
                  onClick={onClick}
                  label={`${additionalFilterCount} ${wording}`}
                  tooltip={tooltip}
                />
            </div>
        );
    }
    return (
        <div
          className={classnames(styles.additionalFilterButton, { [styles.disabled]: disabled, [styles.small]: small })}
        >
            <a
              href="#"
              className={classnames(styles.link, { [styles.linkActive]: active })}
              onClick={(e) => { onClick(); e.preventDefault(); }}
            >+ Add filter
            </a>
        </div>
    );
};
AdditionalFilterButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    postText: customPropTypes.postTextFilter,
    postTextExclude: customPropTypes.postTextExcludeFilter,
    postTag: customPropTypes.postTagFilter,
    adCampaign: customPropTypes.adCampaignFilter,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    showPrefixWhenFilterIsSet: PropTypes.bool
};

AdditionalFilterButton.defaultProps = {
    small: false,
    disabled: false,
    active: false,
    showPrefixWhenFilterIsSet: true
};

export default AdditionalFilterButton;
