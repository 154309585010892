import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectActivePostTagById } from 'src/selectors/postTags';
import FilterToken from 'src/components/FilterToken';

const TagFilterToken = ({
    onDeleteClick, label, onClick, active, error, onError, showTooltip
}) => (
    <FilterToken
      onDeleteClick={onDeleteClick}
      label={label}
      onClick={onClick}
      active={active}
      error={error}
      onError={onError}
      showTooltip={showTooltip}
    />
);

TagFilterToken.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    error: PropTypes.bool,
    onError: PropTypes.func,
    showTooltip: PropTypes.bool
};

TagFilterToken.defaultProps = {
    active: false,
    error: false,
    onError: () => {},
    showTooltip: true
};

const makeMapStateToProps = () => {
    const selectActivePostTagById = makeSelectActivePostTagById();
    return (state, ownProps) => {
        let label = 'Invalid tag';
        const postTag = selectActivePostTagById(state, ownProps.postTagId);
        const error = !postTag;
        if (postTag) {
            label = postTag.name;
        }
        return {
            label,
            error
        };
    };
};

export default connect(makeMapStateToProps)(TagFilterToken);
