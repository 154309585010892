import React from 'react';
import Popover from 'src/components/popovers/layout/Popover';
import * as customPropTypes from 'src/customPropTypes';
import _lowerCase from 'lodash/lowerCase';

const styles = {
    padding: '20px'
};

const AvailableConnectedAuthAccountInfoPopover = ({ accountType }) => {
    const accountName = _lowerCase(accountType);
    return (
        <Popover id="availableConnectedAccountInfo" width={300}>
            <div style={styles}>
                {
                    `These ${accountName} are connected by an admin user of the page. They are not yet added to your account.`
                }
                <br /><br />
                {
                    `The admin can always remove quintly's access to their ${accountName}`
                }
                <br /><b />
                <a href="https://support.quintly.com/en/articles/6872831-profiles-connected-with-authentication-link" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
        </Popover>
    );
};

AvailableConnectedAuthAccountInfoPopover.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired
};

export default AvailableConnectedAuthAccountInfoPopover;
