import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import styles from 'src/stylesheets/popovers/privateStatisticsUseCaseInfo.scss';

const PrivateStatisticsUseCaseInfoPopover = () => (
    <Popover id="authTransactionHelp">
        <div className={styles.wrapper}>
            <div className={styles.text}>
                Private stats use cases are permissions we grouped together into different use cases for easier authentication.
                Granting the permissions will allow you to see private statistics in your quintly account.
                To see the required permissions for each use case activated, check the info icon above the use case.
                <br /><br />
            </div>
            <a
              href="https://support.quintly.com/en/articles/4412153-what-are-private-statistic-use-cases"
              target="_blank"
              rel="noopener noreferrer"
            >
                Learn more
            </a>
        </div>
    </Popover>
);

export default PrivateStatisticsUseCaseInfoPopover;
