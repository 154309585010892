import React from 'react';
import styles from 'src/stylesheets/responsiveEditableItemList.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import PostPostTagListItem from 'src/components/views/postTags/PostPostTagListItem';
import * as customPropTypes from 'src/customPropTypes';
import PostPostTagsTooltip from 'src/components/views/postTags/PostPostTagsTooltip';
import { makeSelectDerivedPostPostTagsByPostId } from 'src/selectors/posts';
import { connect } from 'react-redux';
import { makeSelectActivePostTagsByIds } from 'src/selectors/postTags';
import withTooltip from 'src/components/withToolTip';

const maxNumberOfItems = 3;
const memoizedGetSlicedItems = memoizeOne((items, start, end) => items.slice(start, end));
const getPostTagIds = memoizeOne((postPostTags) => Object.keys(postPostTags));
const PostPostTagListItemWithTooltip = withTooltip(PostPostTagListItem);

const renderItems = (items) => items.map((item) => (
    <div key={item.postTagId} className={styles.item}>
        <PostPostTagListItemWithTooltip tooltip={<PostPostTagsTooltip postPostTags={[item]} />} trigger="hover" placement="top" name={item.postTagName} />
    </div>
));

const PostPostTagList = (props) => {
    const {
        postPostTags, className, editButton
    } = props;
    const itemCount = postPostTags.length;
    const wrapperClassName = classnames(styles.wrapper, className);
    const visibleItems = memoizedGetSlicedItems(postPostTags, 0, maxNumberOfItems);
    const hiddenItems = memoizedGetSlicedItems(postPostTags, maxNumberOfItems, itemCount);
    if (itemCount > 0) {
        if (itemCount > maxNumberOfItems) {
            const remainingItemCount = itemCount - maxNumberOfItems;
            return (
                <div className={wrapperClassName}>
                    {renderItems(visibleItems)}
                    <div className={styles.moreItems}>
                        <PostPostTagListItemWithTooltip
                          className={styles.test}
                          tooltip={<PostPostTagsTooltip postPostTags={hiddenItems} />}
                          trigger="hover"
                          placement="top"
                          name={`+${remainingItemCount}`}
                        />
                    </div>
                    {editButton}
                </div>
            );
        }

        return (
            <div className={wrapperClassName}>
                {renderItems(postPostTags)}
                {editButton}
            </div>
        );
    }

    return (
        <div className={wrapperClassName}>
            <div className={styles.item}>
                <PostPostTagListItem name="No tags" disabled />
            </div>
            {editButton}
        </div>
    );
};

PostPostTagList.propTypes = {
    post: customPropTypes.post.isRequired,
    postPostTags: customPropTypes.derivedPostPostTags.isRequired,
    editButton: PropTypes.node,
    className: PropTypes.string
};

PostPostTagList.defaultProps = {
    className: ''
};

const makeMapStateToProps = () => {
    const selectDerivedPostPostTagsByPostId = makeSelectDerivedPostPostTagsByPostId();
    const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();

    return (state, ownProps) => {
        const { postPostTags } = ownProps.post;
        const activePostTags = selectActivePostTagsByIds(state, getPostTagIds(postPostTags));
        return {
            postPostTags: selectDerivedPostPostTagsByPostId(state, postPostTags, activePostTags)
        };
    };
};

export default connect(makeMapStateToProps)(PostPostTagList);
