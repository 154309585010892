import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import Date from 'src/components/Date';
import React from 'react';
import styles from 'src/stylesheets/popovers/publicProfileDataAvailabilityPopover.scss';
import Popover from 'src/components/popovers/layout/Popover';

const ProfileDataAvailabilityPopover = (props) => {
    const { profile } = props;
    const audienceDataSinceTime = _get(profile, 'audienceDataSinceTime', 'n/a');
    const interactionDataSinceTime = _get(profile, 'interactionDataSinceTime', 'n/a');
    return (
        <Popover id="publicDataAvailabilityPopover">
            <div className={styles.wrapper}>
                <h2 className={styles.headline}>Public data availability</h2>
                <div className={styles.text}>
                    {
                        profile.audienceDataSinceTime === 'n/a' && profile.interactionDataSinceTime === 'n/a'
                        && (
                            <span>
                                There is no data yet for this profile and our importers are most likely to finish their
                                first-time imports in a few moments.
                                Once our data importers finished you will get more information about how much
                                historic data is available right here.
                            </span>
                        )
                    }
                    {
                        (profile.audienceDataSinceTime !== 'n/a' || profile.interactionDataSinceTime !== 'n/a')
                        && (
                            <div>
                                <span>
                                    Audience data since { audienceDataSinceTime !== 'n/a' && <Date date={audienceDataSinceTime} /> } { audienceDataSinceTime === 'n/a' && 'n/a'}
                                    <br />
                                    Interaction data since { interactionDataSinceTime !== 'n/a' && <Date date={interactionDataSinceTime} /> }{ interactionDataSinceTime === 'n/a' && 'n/a' }
                                </span>
                            </div>
                        )
                    }
                </div>
                <h2 className={styles.headline}>Learn more about:</h2>
                <div className={styles.text}>
                    <a href="https://support.quintly.com/en/articles/4412261-audience-data-vs-interaction-data" target="_new">The difference of audience and interaction data</a>
                </div>
                <div className={styles.text}>
                    <a href="https://support.quintly.com/en/articles/4412229-data-availability-of-networks-on-private-and-public-level" target="_new">The availability of historic data</a>
                </div>
            </div>
        </Popover>
    );
};

ProfileDataAvailabilityPopover.propTypes = {
    profile: customPropTypes.profile.isRequired
};

export default ProfileDataAvailabilityPopover;
